import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: 'schlauch', loadChildren: './schlauch/schlauch.module#SchlauchPageModule' },
  { path: 'hydrant', loadChildren: './hydrant/hydrant.module#HydrantPageModule' },
  { path: 'tankpendel', loadChildren: './tankpendel/tankpendel.module#TankpendelPageModule' },
  { path: 'tauchpumpe', loadChildren: './tauchpumpe/tauchpumpe.module#TauchpumpePageModule' },
  { path: 'seilwinde', loadChildren: './seilwinde/seilwinde.module#SeilwindePageModule' },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  { path: 'termsuse', loadChildren: './termsuse/termsuse.module#TermsusePageModule' },
  { path: 'schaummittel', loadChildren: './schaummittel/schaummittel.module#SchaummittelPageModule' },
  { path: 'sandsack', loadChildren: './sandsack/sandsack.module#SandsackPageModule' },
  { path: 'about', loadChildren: './about/about.module#AboutPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
