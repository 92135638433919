import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { HilfePage } from "./hilfe/hilfe.page";
import { HilfePageModule } from "./hilfe/hilfe.module";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

import { HttpClientModule } from "@angular/common/http";
import { TranslateService } from "./translate.service";
import { MeasurementsService } from "./measurements.service";
import { TranslatePipe } from "./translate.pipe";
import { SharedpipeModule } from "./sharedpipe/sharedpipe.module";
import { MeasurementPipe } from "./measurement.pipe";
import { Globals } from "./globals";

export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use("de");
}
export function setupMeasurementFactory(
  service: MeasurementsService
): Function {
  return () => service.use("mm");
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [HilfePage],
  imports: [
    BrowserModule,
    FormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    HttpClientModule,
    SharedpipeModule,
    HilfePageModule
  ],
  providers: [
    StatusBar,
    Globals,
    TranslatePipe,
    MeasurementsService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupMeasurementFactory,
      deps: [MeasurementsService],
      multi: true
    },
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
