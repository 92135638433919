//hello
import { Component, OnInit } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Globals } from "./globals";
import { Router } from "@angular/router";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { TranslateService } from "./translate.service";
import { MeasurementsService } from "./measurements.service";

import { environment } from "src/environments/environment";
import { TranslatePipe } from "./translate.pipe";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  providers: [Globals]
})
export class AppComponent {
  einheit: string;
  language: string;
  router: Router;
  location: Location;
  globals: Globals;

  public appPages = [
    {
      title: "Home",
      url: "/home",
      icon: "home"
    },

    {
      title: "druckverlustschlauch",
      url: "/schlauch",
      icon: "calculator"
    },
    {
      title: "Hydrant",
      url: "/hydrant",
      icon: "calculator"
    },
    {
      title: "Tankpendelverkehr",
      url: "/tankpendel",
      icon: "calculator"
    },
    {
      title: "Tauchpumpe",
      url: "/tauchpumpe",
      icon: "calculator"
    },
    {
      title: "Seilwinde",
      url: "/seilwinde",
      icon: "calculator"
    },

    /*{
      title: "Schaummittelberechnung",
      url: "/schaummittel",
      icon: "calculator"
    },
    {
      title: "Sandsackrechner",
      url: "/sandsack",
      icon: "calculator"
    },*/
    {
      title: "termuse",
      url: "/termsuse",
      icon: "clipboard"
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private _globals: Globals,
    private _router: Router,
    private _location: Location,
    private translate: TranslateService,
    private translatePipe: TranslatePipe,
    private measurement: MeasurementsService
  ) {
    this.router = _router;
    this.location = _location;
    this.globals = _globals;
    this.einheit = _globals.unit;
    this.language = _globals.language;
    this.initializeApp();
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    var lg;
    if (navigator.language.substring(0, 2) == "de") {
      lg = "de";
    } else {
      lg = "e";
    }
    var language = localStorage.getItem("language") || lg;

    var measurement = localStorage.getItem("measurement") || "mm";

    this.globals.unit = measurement;
    this.einheit = measurement;
    this.measurement.use(measurement);
    this.globals.language = language;
    this.language = language;
    this.translate.use(language);

    // this.version.initVersionCheck(environment.versionCheckURL)
    /* const cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#8f0b01"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: this.translatePipe.transform("cookieMessage"),
        dismiss: this.translatePipe.transform("cookieDismiss"),
        link: this.translatePipe.transform("cookieLinktext")
      }
    });*/
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString("#f21818");
      this.splashScreen.hide();
    });
  }

  changeeinheit() {
    this.globals.unit = this.einheit;
    /*this.router.navigateByUrl("/", {skipLocationChange: true}).then(()=>
    this.router.navigate([this.location.path()])); */
    this.measurement.use(this.einheit);
    localStorage.setItem("measurement", this.einheit);
    if (this.einheit == "mm") {
      this.globals.duesedurch = 22;
      this.globals.druckoffen = 5;
      this.globals.druckgeschlossen = 8;
      this.globals.pumpeingang = 1.5;
      this.globals.startdruck = 10;
      this.globals.schlauchzahl = 5;
      this.globals.durchfluss = 800;
      this.globals.hoheunterschied = 0;
      this.globals.langeschlauch = 20;
      this.globals.schlauchtyp = 75;
      this.globals.entfernungwasser = 3;
      this.globals.anzahltank1 = 11;
      this.globals.fzgliter1 = 2000;
      this.globals.anzahltank2 = 0;
      this.globals.fzgliter2 = 4000;
      this.globals.fuellentank = 1000;
      this.globals.entleerentank = 1000;
      this.globals.durchschnittsgeschwindigkeit = 30;
      this.globals.rustzeitfuellen = 1.5;
      this.globals.rustzeitentleeren = 1.5;
      this.globals.deckungsbreiterohr = 60;
      this.globals.flowstrahlrohr = 100;
      this.globals.deckungsbreiterohrboden = 30;
      this.globals.flowrohrwald = 200;
      this.globals.flowmax = 2000;
      this.globals.foedermax = 20;
      this.globals.schlauchzahl1 = 1;
      this.globals.hoehendifferenz = 0;
      this.globals.schlauchlaenge = 20;
      this.globals.parallelleitung = 1;
      this.globals.druckendeschlauch = 0.1;
    } else {
      this.globals.duesedurch = 1;
      this.globals.druckoffen = 70;
      this.globals.druckgeschlossen = 110;
      this.globals.pumpeingang = 20;
      this.globals.startdruck = 120;
      this.globals.schlauchzahl = 5;
      this.globals.durchfluss = 150;
      this.globals.hoheunterschied = -30;
      this.globals.langeschlauch = 60;
      this.globals.schlauchtyp = 2;
      this.globals.entfernungwasser = 2.1;
      this.globals.anzahltank1 = 3;
      this.globals.fzgliter1 = 1500;
      this.globals.anzahltank2 = 2;
      this.globals.fzgliter2 = 1000;
      this.globals.fuellentank = 300;
      this.globals.entleerentank = 300;
      this.globals.durchschnittsgeschwindigkeit = 20;
      this.globals.rustzeitfuellen = 1.5;
      this.globals.rustzeitentleeren = 1.5;
      this.globals.deckungsbreiterohr = 60;
      this.globals.flowstrahlrohr = 100;
      this.globals.deckungsbreiterohrboden = 30;
      this.globals.flowrohrwald = 200;
      this.globals.flowmax = 500;
      this.globals.foedermax = 70;
      this.globals.schlauchzahl1 = 1;
      this.globals.hoehendifferenz = 0;
      this.globals.schlauchlaenge = 50;
      this.globals.parallelleitung = 1;
      this.globals.druckendeschlauch = 0;
    }
  }

  changelanguage() {
    this.globals.language = this.language;
    localStorage.setItem("language", this.language);
    this.translate.use(this.language);
  }
}
