import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
}
)
export class Globals {
  language: string = 'de';
  unit: string = 'mm';
  duesedurch: number =22
  druckoffen:number =5
  druckgeschlossen:number=8
  pumpeingang:number=1.5
  startdruck:number= 10
  schlauchzahl:number=5
  durchfluss:number=800
  hoheunterschied:number=0
  langeschlauch:number=20
  schlauchtyp:number=75
  entfernungwasser:number=3
  anzahltank1:number=11
  fzgliter1:number=2000
  anzahltank2:number=0
  fzgliter2:number=4000
  fuellentank:number=1000
  entleerentank:number=1000
  durchschnittsgeschwindigkeit:number=30
  rustzeitfuellen:number=1.5
  rustzeitentleeren:number=1.5
  deckungsbreiterohr:number=60
  flowstrahlrohr:number=100
  deckungsbreiterohrboden:number=30
  flowrohrwald:number=200
  flowmax:number=2000
  foedermax:number=20
  schlauchzahl1:number=1
  hoehendifferenz:number=0
  schlauchlaenge:number=20
  parallelleitung:number=1
  druckendeschlauch:number=0.1
}