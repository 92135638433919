import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementsService } from './measurements.service';


@Pipe({
  name: 'measurement',
  pure: false,
})
export class MeasurementPipe implements PipeTransform {

  constructor(private measurement: MeasurementsService) {}
  transform(key: any): any {
   
    return this.measurement.data[key] || key;
  }

}
