import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslatePipe} from '../translate.pipe';
import { MeasurementPipe } from '../measurement.pipe';


@NgModule({
  declarations: [TranslatePipe, MeasurementPipe],
  imports: [
    CommonModule
  ],
  exports:[TranslatePipe, MeasurementPipe]
})
export class SharedpipeModule { }
